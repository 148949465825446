function getSystemInfoStr(){

    var systemObj = getSystemInfoObject();

    var str = '';
    // str += "<h3>Navigator Object Data</h3>";
	// str += "<p>";
	// // str += "navigator.appCodeName: " + navigator.appCodeName + "<br>";
	// // str += "navigator.appName: " + navigator.appName + "<br>";
	// // str += "navigator.appVersion: " + navigator.appVersion + "<br>";
	// // str += "navigator.userAgent: " + navigator.userAgent + "<br>";
	// // str += "navigator.platform: " + navigator.platform + "<br>";
    // str += "</p>";
	
	str += '<h2>System Report</h2>';
	
	str += '<h3>Operating System</h3>';	
	str += '<p>'+Detectizr.os.name +' '+Detectizr.os.version+ '</p>';
	
    str += '<h3>Browser</h3>';
	str += '<p>';
	str += Detectizr.browser.name + ' ' + Detectizr.browser.version+'<br>';
	str += Detectizr.browser.userAgent;
    str += '</p>';
    
	str += '<h3>Screen</h3>';	
	str += '<p>W: '+systemObj.screen.w +',  H: '+systemObj.screen.h+ '</p>';
	str += '<p>Current breakpoint: '+systemObj.screen.bp+ '</p>';

    return str;
}

function getSystemInfoObject(){
    var breakpoint = '';
    try {
        breakpoint = responsiveUtils.getCurrentViewport();
    }catch(err){
    }
    var systemObj = {
        os: {
            name: Detectizr.os.name,
            version: Detectizr.os.version,
        },
        // device:{

        // },
        browser: {
            ua: Detectizr.browser.userAgent
        },
        screen: {
            w: window.innerWidth,
            h: window.innerHeight,
            bp: breakpoint
        }
    };
    return systemObj
}