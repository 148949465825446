function UpdateManager(){
    var self = this;
    
    this.remotePath = '';
    this.filesToUpdateArray = [];

    this.init = function(){

        // check to see if we're loading content from the remote CDN
        if (LaunchConfig.cdn.enabled){
            self.remotePath = LaunchConfig.cdn.endpoint.url.split('https:').join(window.location.protocol)+LaunchConfig.course.uid+'/';
            // if (window.location.protocol == 'https:'){
            //     self.remotePath = LaunchConfig.cdn.endpoint.url.https+LaunchConfig.uid;
            // } else {
            //     self.remotePath = LaunchConfig.cdn.endpoint.url.http+LaunchConfig.uid;
            // }
            
            if (!LaunchConfig.cdn.updatesOnly){
                // override the rootpath if not updates only
                rootPath = self.remotePath;
            }
        }
    }

    this.checkForUpdates = function(obj){
        if (LaunchConfig.cdn.enabled && LaunchConfig.cdn.updatesOnly){

            // lets load remote json
            loadJSON(self.remotePath+'content_updater.json',
                function(data) { 
                    if (data.update.files.length){
                        // there are updated files

                        console.warn('UpdateManager: update found'); 
                        console.warn('UpdateManager: NOTES:', data.update.notes); 
                        console.warn('UpdateManager: files', data.update.files); 
                        // is the update active? If not it can be tested using urlParameter ?update=1
                        if (data.active == true || (data.active == false && self.getURLParameter('update') == 1)){
                            // check course uid the same
                            if (LaunchConfig.course.uid == data.course.uid){
                                // check this version can be updated - must be > min version and not have a version greater than remote
                                if (LaunchConfig.course.version >= data.update.minVersion && LaunchConfig.course.version <= data.update.version){
                                    self.filesToUpdateArray = data.update.files;
                                    // console.log('update', self.filesToUpdateArray);
                                } else {
                                    console.warn('UpdateManager: update (min:'+data.update.minVersion+'/max:'+data.update.version+') not valid for this version:'+LaunchConfig.course.version ); 
                                }
                            } else {
                                console.warn('UpdateManager: course code '+LaunchConfig.course.code+' does not match '+data.course.code); 
                            }
                        } else {
                            console.warn('UpdateManager: update not active'); 
                        }
                    } else {
                        console.warn('UpdateManager: no files to update'); 
                    }
                    obj.complete.call();
                },
                function(xhr) { 
                    console.error(xhr); 
                    // error - just load normally
                    // initialiser.loadResource(filesToLoadArray);
                    obj.complete.call();
                }
            )
        } else {
            // initialiser.loadResource(filesToLoadArray);
            obj.complete.call();
        }
    }

    this.getURL = function(url){
        // console.log('update', self.filesToUpdateArray);
        // only applies to where CDN in use
        // check Launch filesToUpdateArray to see if this file has an update available
        if (self.filesToUpdateArray.indexOf(url) != -1){
            // check if this file needs to be updated
            url = self.remotePath+url;
            // console.log('update file', url);
        } else {
            // console.log('no update', url);
        }
        return self.nocache(url);
    } 

    this.getUrlExtension = function (url) {
        const lastDotIndex = url.lastIndexOf('.');
        if (lastDotIndex !== -1) {
            return url.substring(lastDotIndex+1).toLowerCase();
        } else {
            return '';
        }
    }

    this.nocache = function (url) {
        var useCache = true;
        if (LaunchConfig.cache) {
            // course may have optional cache settings
            var extension = self.getUrlExtension(url);
            if (extension == 'js') {
                try {
                    if (url.indexOf('content_'+LaunchConfig.course.code+'_'+languageConfig.languages[currentLanguage].code+'.js') != -1) {
                        // if the compressed langauge js file - apply cache setting of xml
                        extension = 'xml';
                    }   
                } catch (e) {
                }
            }
            
            switch(extension){
                case 'xml':
                    useCache = LaunchConfig.cache.xml;
                case 'js':
                    useCache = LaunchConfig.cache.js;
                    break;
                case 'css':
                    useCache = LaunchConfig.cache.css;
                    break;
                case 'png':
                case 'jpg':
                case 'jpeg':
                case 'gif':
                case 'svg':
                    useCache = LaunchConfig.cache.images;
                    break;
                case 'mp4':
                    useCache = LaunchConfig.cache.video;
                    break;
            }
        }
        // console.log('nocache', url, extension, useCache)
        if (useCache){
            return url;
        }
        var timestamp = new Date().getTime();
        return url + (url.indexOf('?') == -1 ? '?' : '&') + 'nocache=' + timestamp;
    }

    this.getURLParameter = function(name) {
        return decodeURI(
            (RegExp(name + '=' + '(.+?)(&|$)').exec(location.search)||[,null])[1]
        );
    }

    this.init();
}