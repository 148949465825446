// Avoid `console` errors in browsers that lack a console.
(function() {
    var method;
    var noop = function () {};
    var methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }
}());

if (!Array.isArray) {
    Array.isArray = function(arg) {
      return Object.prototype.toString.call(arg) === '[object Array]';
    };
  }

var CourseInitialiser = function () {
    this.error_watch = null;
    this.errors = {
        content: "<p>Sorry - there was a problem loading this content.</p>"
    }
};


// filesToLoadArray - list of minified or concatenated files to load
var filesToLoadArray = LaunchConfig.files;

var rootPath = '';

var updateManager = new UpdateManager();

CourseInitialiser.prototype.loadResource = function(filesArray, callback){
    var file = filesArray.shift();
    var element = document.createElement(file[0]);
    
    var source = updateManager.getURL(rootPath+file[1]);    
    //console.log('CourseLaunch::loadResource', source);

    if (file[0] == "link") {
        // if a link, its a css file we're adding
        element.href = source;
        element.rel = "stylesheet";
    } else {
        // if a script, its a minified js file we're adding
        element.src = source;
    }
    
    element.onload = element.onreadystatechange = function () {
        // our file has loaded
        
        if (!this.readyState || this.readyState == 'complete' || this.readyState == 'loaded'){
            if (callback){
                callback();
            } else {
                if (filesArray.length){
                    // load the next file in filesArray 
                    initialiser.loadResource(filesArray, callback);
                }
            }
        }
    }

    element.onerror = function (e) {
        console.warn('ERROR - file not found at '+rootPath, file);
        // reset root path
        rootPath = '';
        // push file back on
        filesArray.unshift(file);
        initialiser.loadResource(filesArray, callback);
    }

    document.documentElement.appendChild(element);
}

var ieCheck;

CourseInitialiser.prototype.init = function () {
    console.log('init: cdn', LaunchConfig.cdn.enabled);

    console.log('System', getSystemInfoObject())

    var okToLaunch = true;
    // lets check we're not in IE compatability mode

    var minVersionIE = 9;
    ieCheck = IeVersion();
    if (ieCheck.isIE){
        if (ieCheck.compatibilityMode){
            if (ieCheck.actingVersion < minVersionIE){
                okToLaunch = false;
                
            }
        }
    }
    
    if (okToLaunch){
        initialiser.checkForUpdates();
        // initialiser.loadResource(filesToLoadArray);
    } else {
        initialiser.getLanguages();
    }
}

CourseInitialiser.prototype.checkForUpdates = function(){
    // console.log('checkForUpdates')
    //if (LaunchConfig.cdn.enabled && LaunchConfig.cdn.updatesOnly){

        updateManager.checkForUpdates(
            {
                complete: function(){
                    //console.log('complete',filesToLoadArray)
                    initialiser.loadResource(filesToLoadArray);
                }
            });
       /* // lets load remote json
        loadJSON(remotePath+'content_updater.json',
            function(data) { 
                console.log(data); 
                if (data.files.length){
                    // there are updated files
                    if (LaunchConfig.course.code == data.code){
                        // check course code the same
                        if (LaunchConfig.course.version >= data.minVersion && LaunchConfig.course.version <= data.version){
                            // check this version can be updated - must be > min version and not have a version greater than remote
                            filesToUpdateArray = data.files;
                            initialiser.loadResource(filesToLoadArray);
                        }
                    }
                }
            },
            function(xhr) { 
                console.error(xhr); 
                // error - just load normally
                initialiser.loadResource(filesToLoadArray);
            }
        )
    } else {
        initialiser.loadResource(filesToLoadArray);
    }*/
}

CourseInitialiser.prototype.getLanguages = function(){
    initialiser.loadResource([
       ['script', 'config/CourseConfig.js']
    ], function(){
        var defaultLanguageCode = languageConfig.languages[languageConfig.defaultLanguage].code;
        // console.log('CourseConfig loaded', defaultLanguageCode);//, courseConfig)

        // create language select
        var htmlStr = '';
        htmlStr += '<div id="compatabilityModeError">';
            htmlStr += '<div id="languageSelect">';
                htmlStr += '<select id="languageSelector" onchange="initialiser.loadLanguage(this.value)">';
                for (var i = 0; i < languageConfig.languages.length; i ++){
                    if (languageConfig.languages[i] && languageConfig.languages[i].active){
                        htmlStr += '<option value="'+languageConfig.languages[i].code+'">'+languageConfig.languages[i].language+'</option>';
                    }
                }
                htmlStr += '</select>';
            htmlStr += '</div>';
            htmlStr += '<div id="compatabilityMessage">';
            htmlStr += '</div>';
            htmlStr += '<hr>';
            htmlStr += '<h2>System Report:</h2>';
            htmlStr += 'Version: ' + ieCheck.trueVersion + '<br/>';
            htmlStr += 'Compatibility Mode Version: ' + ieCheck.actingVersion + '<br/>';
        htmlStr += '</div>';

        document.body.innerHTML = htmlStr;
        // setTimeout(function(){

        //     var languageSelector = document.getElementById("languageSelector");
            
        //     languageSelector.addEventListener("change", function() {
        //         console.log(languageSelector.value);
        //     });
        // }, 1000);

        initialiser.loadLanguage(defaultLanguageCode);

    });
}

CourseInitialiser.prototype.loadLanguage = function(languageCode){

    initialiser.loadResource([
        ['script', 'course/content/lang/'+languageCode+'/content_'+LaunchConfig.course.code+'_'+languageCode+'.js']
    ], function(){
        // console.log('content loaded', contentData.settings[0].compatibility[0]);

        initialiser.displayCompatabilityMessage();
    });
}

CourseInitialiser.prototype.displayCompatabilityMessage = function(){
    var htmlStr = '';
    htmlStr += '<h1>'+contentData.settings[0].compatibility[0].title[0].Text+'</h1>';
    
    htmlStr += initialiser.getHTMLText(contentData.settings[0].compatibility[0].message[0]).split('[HOSTNAME]').join(window.location.hostname);

    // htmlStr += '<p>It appears your browser is running in a compatibility mode that is preventing this course loading.</p>';
    // htmlStr += '<p>Follow the following steps to resolve this issue:</p>';
    // htmlStr += '<ol>';
    //     htmlStr += '<li>Go to <strong>Tools</strong> and select <strong>Compatibility Mode settings</strong> from the menu</li>';
    //     htmlStr += '<li>From the <strong>Websites you�ve added to Compatibility View</strong> list, select the domain �'+window.location.hostname+'� and then select <strong>Remove</strong></li>';
    //     htmlStr += '<li>Click <strong>Close</strong></li>';
    // htmlStr += '</ol>';

    // htmlStr += '<p>If the problem persists please contact the Novartis helpdesk.</p>';
    
    
    
    document.getElementById('compatabilityMessage').innerHTML = htmlStr;
}

CourseInitialiser.prototype.getHTMLText = function(node){
    var htmlStr = '';
    for (var i = 0; i < node._childorder.length; i++){
        var nodeName = node._childorder[i].node;
        var nodeData = node[nodeName][node._childorder[i].pos];
        htmlStr += '<'+nodeName+'>';
            if (nodeData._childorder){
                if (nodeData._childorder.length){
                    htmlStr += initialiser.getHTMLText(nodeData);
                } else if (nodeData.Text){
                    htmlStr += nodeData.Text;
                }
            }
        htmlStr += '</'+nodeName+'>';
    }
    return htmlStr;
}

function loadJSON(path, success, error)
{
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function()
    {
        if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200) {
                if (success)
                    success(JSON.parse(xhr.responseText));
            } else {
                if (error)
                    error(xhr);
            }
        }
    };
    xhr.open("GET", path, true);
    xhr.send();
}

var initialiser = new CourseInitialiser();

window.onload = function () {

    //load course content
    initialiser.init();
};